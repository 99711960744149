import { FAQ_URL_PATH } from '../constants';
import { getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import { HotlineNumber } from '@ncs-frontend-monorepo/content-library';

export const checkFAQURL = (
  path: string,
): {
  isValid: boolean;
  mainCategorySlug: string;
  subCategorySlug: string;
  faqSlug: string;
} => {
  const faqSlug = path.split('/').filter((e) => String(e).trim());

  return {
    isValid: path.startsWith(FAQ_URL_PATH) && faqSlug.length >= 3,
    mainCategorySlug: faqSlug?.[2], // e.g. /privatkunden/hilfe/main-category
    subCategorySlug: faqSlug?.[3], // e.g./privatkunden/hilfe/main-category/sub-category (or FAQ)
    faqSlug: faqSlug?.[4], // e.g. /privatkunden/hilfe/main-category/sub-category/faq-slug
  };
};

export const getReplaceText = () => {
  const company_name = getEnv().SITE;
  const company_name_lc = company_name.toLocaleLowerCase();
  const company_mandant_lc = getEnv().BUSINESS_UNIT;
  const company_phone = isNC()
    ? HotlineNumber.NetCologne.PK.tel
    : HotlineNumber.NetAachen.PK.tel;

  return { company_name, company_name_lc, company_mandant_lc, company_phone };
};
