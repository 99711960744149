import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Text,
  Spacer,
  Button,
  useClickOutside,
  LoadingSpinner,
} from 'dss-ui-library';
import { Form } from 'formik';
import { FAQSearchResult } from '..';
import { SearchInputField } from './SearchInputField';
import { SearchResultList } from './SearchResultList';
import styles from './FAQSearch.module.scss';

export const FAQSearchForm: React.FC = () => {
  const [searchResult, setSearchResult] = useState<FAQSearchResult | null>();
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const resultListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsSearchResultVisible(
      !!searchResult?.items && searchResult.items.length > 0,
    );
  }, [searchResult]);

  useClickOutside(inputRef, () => setIsSearchResultVisible(false), formRef);
  const handleFocus = () => {
    if (searchResult?.items && searchResult?.items?.length > 0) {
      setIsSearchResultVisible(true);
    }
  };

  return (
    <div className={styles.search} ref={formRef}>
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t1" color="white" element="h1">
                Wobei können wir dir helfen?
              </Text>
            </Grid.Column>
          </Grid.Row>
          <Spacer b={2} />
          <Grid.Row>
            <Grid.Column m={10} s={9}>
              <SearchInputField
                inputRef={inputRef}
                onFocus={handleFocus}
                setLoading={setLoading}
                setSearchResults={setSearchResult}
              />
            </Grid.Column>
            <Grid.Column m={2} s={3}>
              <Button
                color="red"
                e2e="search-bar"
                fullWidth
                variant="primary"
                type="submit"
                className={styles.searchSubmit}
              >
                Suchen
              </Button>
            </Grid.Column>
            <Grid.Column m={10} s={9} className={styles.resultWrapper}>
              {isLoading ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpinner theme="blue" />
                </div>
              ) : (
                isSearchResultVisible && (
                  <SearchResultList
                    faqList={searchResult}
                    resultListRef={resultListRef}
                  />
                )
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};
