import React, { ChangeEvent, RefObject } from 'react';
import { useDebounce } from 'dss-ui-library';
import { FAQSearchResult, fetchFAQSearchResult } from '../../../index';
import styles from './SearchBar.module.scss';
import { FormikFields } from '@ncs-frontend-monorepo/forms';

interface SearchInputFieldProps {
  inputRef: RefObject<HTMLInputElement>;
  onFocus: () => void;
  setLoading: (loading: boolean) => void;
  setSearchResults: (results: FAQSearchResult | null) => void;
}

export const SearchInputField: React.FC<SearchInputFieldProps> = ({
  inputRef,
  onFocus,
  setLoading,
  setSearchResults,
}) => {
  const handleInput = useDebounce(async (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;

    if (searchTerm.length < 2) {
      return setSearchResults(null);
    }

    setLoading(true);

    const results = await fetchFAQSearchResult({
      searchTerm,
    });

    if (results) {
      setSearchResults(results);
      setLoading(false);
    } else {
      setSearchResults(null);
      setLoading(false);
    }
  }, 300);

  return (
    <div className={styles.textFieldWrapper} ref={inputRef}>
      <FormikFields.TextField
        e2e="faq-search"
        label=""
        name="searchInput"
        onChange={handleInput}
        onFocus={onFocus}
        placeholder="Gib hier deine Suchbegriffe ein"
        type="text"
      />
    </div>
  );
};
