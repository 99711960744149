import React, { RefObject } from 'react';
import { Background, Text } from 'dss-ui-library';
import { FAQSearchResult, SearchForm } from '../..';
import { FAQ_SEARCH_URL_PATH, FAQ_URL_PATH } from '../../../constants';
import { getReplaceText } from '../../../helper';
import { replaceDynamicText } from '@ncs-frontend-monorepo/utils';
import styles from './SearchResultList.module.scss';
import { useFormikContext } from 'formik';
import { ChevronLink, Link } from '@ncs-frontend-monorepo/content-library';

interface SearchResultsListProps {
  faqList?: FAQSearchResult | null;
  resultListRef: RefObject<HTMLDivElement>;
}

export const SearchResultList: React.FC<SearchResultsListProps> = ({
  faqList,
  resultListRef,
}) => {
  const { values } = useFormikContext<SearchForm>();
  const replaceText = getReplaceText();

  if (!faqList?.items || !faqList) return null;

  return (
    <div className={styles.searchResultList} ref={resultListRef}>
      <Background color="white">
        {faqList.items.map((faq, ix) => (
          <Link
            className={styles.searchResultItem}
            url={FAQ_URL_PATH + faq.slugBreadcrumb}
            key={faq?.dataObject?.uid || '' + ix}
          >
            <Text appearance="t4" color="black">
              {replaceDynamicText(faq.question, replaceText)}
            </Text>
          </Link>
        ))}
        <ChevronLink
          appearance="t5"
          className={styles.resultLink}
          color="blue"
          iconPosition="right"
          url={`${FAQ_SEARCH_URL_PATH}?q=${values?.searchInput}`}
        >
          Alle Ergebnisse anzeigen
        </ChevronLink>
      </Background>
    </div>
  );
};
