import { checkFAQURL } from '../helper';
import { CATEGORY_UID } from '../constants';
import { FAQCategory } from '../';
import { fetchFAQCategories } from './index';
import { ContentType } from '@ncs-frontend-monorepo/content-library';

interface FAQPageTypeCategory {
  type: ContentType.FAQ_CATEGORY_PAGE;
  mainCategoryUID: number;
  activeCatgoryUID: number;
  category: FAQCategory;
}

interface FAQPageTypeFAQ {
  type: ContentType.FAQ_PAGE;
  categoryUID: number;
}

export const getFAQPageType = async (
  path: string,
): Promise<FAQPageTypeCategory | FAQPageTypeFAQ | null> => {
  const { isValid, mainCategorySlug, subCategorySlug, faqSlug } =
    checkFAQURL(path);
  let isFAQ = !!faqSlug;
  let mainCategory: FAQCategory | null = null;
  let subCategory: FAQCategory | null = null;

  // no valid FAQ URL
  if (!isValid) {
    return null;
  }

  // get all faq categories
  const mainCategories = await fetchFAQCategories({
    parentCategoryUID: CATEGORY_UID.NC,
  });

  if (mainCategories) {
    mainCategory =
      mainCategories.find((mainCat) => mainCat.slug === mainCategorySlug) ||
      null;

    // no matching URL in faq
    if (!mainCategory) {
      return null;
    }

    const mainCategoryUID = mainCategory.uid;
    let activeCatgoryUID = mainCategoryUID;

    // URL has subcategory / FAQ
    if (subCategorySlug) {
      const subCategories = await fetchFAQCategories({
        parentCategoryUID: mainCategoryUID,
      });

      if (subCategories) {
        subCategory =
          subCategories.find((subCat) => subCat.slug === subCategorySlug) ||
          null;

        // no matching URL
        if (subCategory) {
          activeCatgoryUID = subCategory.uid;
        } else {
          // no subcategory slug is FAQ
          isFAQ = true;
        }
      }
    }

    return isFAQ
      ? {
          type: ContentType.FAQ_PAGE,
          categoryUID: activeCatgoryUID,
        }
      : {
          type: ContentType.FAQ_CATEGORY_PAGE,
          mainCategoryUID,
          activeCatgoryUID,
          category: mainCategory,
        };
  }

  return null;
};
