import { fetchFAQCategories, fetchFAQList, FAQCategory } from '../';
import { ContentType } from '@ncs-frontend-monorepo/content-library';
import { getDefaultFAQBreadcrumb } from './getFAQBreadcrumb';

interface GetFAQCategoryPage {
  path: string;
  mainCategoryUID: number;
  activeCatgoryUID: number;
  category: FAQCategory;
}

export const getFAQCategoryPage = async ({
  mainCategoryUID,
  activeCatgoryUID,
  category,
}: GetFAQCategoryPage) => {
  const [faqCategories, faqList] = await Promise.all([
    fetchFAQCategories({ parentCategoryUID: mainCategoryUID }),
    fetchFAQList({ categoryUID: activeCatgoryUID }),
  ]);

  return {
    pageId: 12345,
    content: [
      {
        component: ContentType.FAQ_CATEGORY_PAGE,
        props: {
          mainCategoryTitle: category.title,
          mainCategorySlug: category.slug,
          mainCategoryUID: category.uid,
          activeUID: activeCatgoryUID,
          subCategories: faqCategories,
          defaultFaqs: faqList,
        },
      },
    ],
    meta: {
      seoTitle: category?.seoTitle || '',
      title: category?.title || '',
      description: category?.seoDescription || '',
    },
    breadcrumbs: [
      ...getDefaultFAQBreadcrumb(),
      ...[
        {
          title: category.title,
          pageId: category.uid,
        },
      ],
    ],
  };
};
