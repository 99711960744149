import { ChevronLink, Expandable, Grid } from 'dss-ui-library';
import { FAQCategory } from '..';
import { FAQCategoryLink } from '../FAQCategoryLink';
import { useMemo, useState } from 'react';
import { useBreakpoint } from '@ncs-frontend-monorepo/utils';

export interface FAQCategoriesProps {
  categories: FAQCategory[];
}

export function FAQCategories({
  categories,
}: FAQCategoriesProps): React.ReactNode {
  const { compare } = useBreakpoint();
  const showExpandable = compare('isSmaller', 's');
  const [isExpanded, setIsExpanded] = useState(false);

  const firstThreeElements = useMemo(
    () => categories.filter((_, i) => i < 3),
    [categories],
  );
  const remainingElements = useMemo(
    () => categories.filter((_, i) => i >= 3),
    [categories],
  );

  return (
    <Grid>
      <Grid.Row>
        {firstThreeElements.map((category) => (
          <Grid.Column s={4} key={category.uid} marginBottom={3}>
            <FAQCategoryLink
              icon={category.icon}
              slug={category.slug}
              title={category.title}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
      <Expandable
        e2e="faq-categories"
        expanded={showExpandable ? isExpanded : true}
      >
        <Grid.Row>
          {remainingElements.map((category) => (
            <Grid.Column s={4} key={category.uid} marginBottom={3}>
              <FAQCategoryLink
                icon={category.icon}
                slug={category.slug}
                title={category.title}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Expandable>
      {showExpandable && (
        <Grid.Row>
          <Grid.Column hCenter>
            <ChevronLink
              appearance="t5_2"
              rotation={isExpanded ? 'up' : 'down'}
              onClick={() => setIsExpanded((e) => !e)}
            >
              {isExpanded ? 'Weniger Themen anzeigen' : 'Mehr Themen anzeigen'}
            </ChevronLink>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}
