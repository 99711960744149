import { BusinessUnit } from '@ncs-frontend-monorepo/utils';
import { ContentVideo } from '@ncs-frontend-monorepo/content-library';

export * from './FAQCategories';
export * from './FAQCategoryPage';
export * from './FAQPage';
export * from './FAQCategoryLink';
export * from './FAQList';
export * from './FAQSearch';
export * from './FAQSearchPage';
export * from './FAQSearch/Form';

export type FAQCategory = {
  title: string;
  seoTitle: string;
  seoDescription: string;
  seoHeadline: string;
  seoText: string;
  slug: string;
  icon: number;
  uid: number;
  pid: number;
  businessUnit: BusinessUnit;
  '@id': string;
  parentCategory?: FAQCategory;
  optionCount?: number;
  count?: number;
};

export type Categories = {
  title: string;
  slug: string;
  uid: number;
  '@id': string;
};

export type FAQ = {
  title: string;
  seoTitle: string;
  seoDescription: string;
  seoHeadline: string;
  seoText: string;
  question: string;
  teaser: string;
  answer: string;
  slug: string;
  slugBreadcrumb: string;
  helpful: number;
  notHelpful: number;
  parentCategory?: Categories | null;
  mainCategory?: Categories | null;
  businessUnit: BusinessUnit;
  contentElements?: ContentVideo[];
  uid: number;
  '@id': string;
  dataObject?: FAQ;
};

export type FAQSearchItem = {
  uid: number | null;
  pid: number | null;
  id: string;
  type: string;
  title: string | null;
  content: string;
  url: string;
  resultPreview: string;
  question: string;
  slugBreadcrumb: string;
  teaser: string;
  dataObject: {
    uid: number | null;
    pid: number | null;
    slugBreadcrumb: string;
    question: string;
    teaser: string;
    answer: string;
    categories: FAQCategory[];
    parentCategory: FAQCategory[];
  };
};

export type FAQResults = {
  items: FAQ[];
  totalItems: number;
  page: number;
};

export type FAQSearchResult = {
  items: FAQSearchItem[];
  page?: number;
  question: string;
  totalItems: number;
  uid?: number;
};

export type FAQSearchFacets = {
  optionLabel: string;
  optionValue: string;
  optionCount: number;
  facetFilterValue: string;
  count: number;
};
