import { FAQ } from '../../components/index';
import {
  fetcher,
  getEnv,
  replaceDynamicText,
} from '@ncs-frontend-monorepo/utils';
import { getReplaceText } from '../../helper';
import { faqRequestParams } from '.';

export type FetchFAQParams = {
  faqUID: number;
};
export async function fetchFAQ({
  faqUID,
}: FetchFAQParams): Promise<FAQ | null> {
  const params = new URLSearchParams({
    ...faqRequestParams,
  });

  const response = await fetcher(
    `${getEnv().API_V2_URL}/faqs/${faqUID}?${params}`,
  );
  if (!response) {
    return null;
  }

  const replaceText = getReplaceText();

  response.question = replaceDynamicText(response.question, replaceText);
  response.answer = replaceDynamicText(response.answer, replaceText);

  return response;
}
