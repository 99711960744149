import { FAQSearch } from '../FAQSearch';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr/immutable';
import {
  fetchFaqSearchCategories,
  fetchFAQSearchResult,
  FetchFAQSearchResultParams,
} from '../../utils';
import {
  ChevronLink,
  Grid,
  LoadingSpinner,
  Pagination,
  Spacer,
  Tag,
  Text,
} from 'dss-ui-library';
import { FAQ_URL_PATH, NUMBER_OF_FAQS } from '../../constants';
import { FAQList } from '../FAQList';
import styles from '../CategoryTags/CategoryTags.module.scss';
import { FAQSearchFacets } from '../index';
import { useFAQStore } from '../../store';
import { useScrollTo } from '@ncs-frontend-monorepo/utils';

export const FAQSearchPage: React.FC = () => {
  const { searchParams, setSearchParams, resetSearchParams } = useFAQStore();
  const currentPage = useRef(searchParams?.page ?? 1);
  const [fetchParams, setFetchParams] = useState<FetchFAQSearchResultParams>();
  const [searchCategoryParams, setSearchCategoryParams] = useState<{
    searchTerm: string;
  }>();
  const [currentFilterValue, setCurrentFilterValue] = useState<
    FAQSearchFacets['optionValue'] | null
  >(null);
  const [isInitialView, setIsInitialView] = useState(true);

  const { data: faqSearchResult, isValidating } = useSWR(
    () => (fetchParams ? fetchParams : null),
    fetchFAQSearchResult,
  );

  const { data: faqSearchCategoryResult } = useSWR(
    () => (searchCategoryParams ? searchCategoryParams : null),
    fetchFaqSearchCategories,
  );

  const [searchValue, setSearchValue] = useState('');
  const faqWrapperRef = useRef<HTMLDivElement>(null);
  const scrollToTarget = useScrollTo(faqWrapperRef);

  const router = useRouter();

  const noOfPages = useMemo(
    () =>
      faqSearchResult
        ? Math.trunc(faqSearchResult.totalItems / NUMBER_OF_FAQS) +
          (faqSearchResult.totalItems % NUMBER_OF_FAQS > 0 ? 1 : 0)
        : 1,
    [faqSearchResult],
  );

  const noResult =
    faqSearchResult?.items?.length === 0 &&
    !isValidating &&
    faqSearchCategoryResult?.length === 0;

  useEffect(() => {
    if (router.isReady) {
      const queryParams = router.query;
      if (typeof queryParams?.q === 'string' && queryParams?.q !== '') {
        setSearchParams({ searchValue: queryParams.q });
        return setSearchValue(queryParams.q);
      }
    }
  }, [router]);

  useEffect(() => {
    if (searchValue !== '') {
      currentPage.current = 1;
      setCurrentFilterValue(null);
      setFetchParams({
        page: 1,
        searchTerm: searchValue,
        categoryFilter: null,
      });
      setSearchCategoryParams({ searchTerm: searchValue });
      setSearchParams({ searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isInitialView && fetchParams) {
      scrollToTarget();
    }
  }, [fetchParams, isInitialView, scrollToTarget]);

  useEffect(() => {
    if (!isInitialView) return;
    if (searchParams?.searchValue) {
      const categoryFilter = searchParams?.category
        ? `${searchParams?.category.facetFilterValue}${searchParams?.category.optionValue}`
        : null;
      setSearchValue(searchParams.searchValue);
      setCurrentFilterValue(categoryFilter);
      setFetchParams({
        page: searchParams?.page ?? 1,
        searchTerm: searchParams.searchValue,
        ...(categoryFilter
          ? {
              categoryFilter,
            }
          : {}),
      });
    }
  }, [searchParams, isInitialView]);

  const handlePagination = useCallback(
    (current: number) => {
      currentPage.current = current;
      setFetchParams({
        page: current,
        searchTerm: searchValue,
        categoryFilter: currentFilterValue,
      });
      setSearchParams({ page: current });
      // @TODO: Just a temporary hotfix
      // - move scrollToTarget to pagination event (needs ui-lib extension)
      if (current !== 1) {
        scrollToTarget();
      }
    },
    [currentFilterValue, searchValue],
  );

  const handleCategorySelect = useCallback(
    (category?: FAQSearchFacets, page = 1) => {
      currentPage.current = page;
      setIsInitialView(false);
      if (!category) {
        setCurrentFilterValue(null);
        setSearchParams({
          category: null,
        });
        setFetchParams({
          page: 1,
          searchTerm: searchValue,
        });
        return;
      }
      const isCurrentUID = category.optionValue === currentFilterValue;

      setCurrentFilterValue(
        isCurrentUID
          ? null
          : `${category.facetFilterValue}${category.optionValue}`,
      );

      setFetchParams({
        page,
        searchTerm: searchValue,
        categoryFilter: `${category.facetFilterValue}${category.optionValue}`,
      });

      setSearchParams({
        page,
        searchValue,
        category,
      });
    },
    [currentFilterValue, resetSearchParams, searchValue, setSearchParams],
  );

  const handleCategoryKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement>,
      category?: FAQSearchFacets,
    ) => {
      if (event.key === 'Enter') {
        handleCategorySelect(category);
      }
    },
    [],
  );

  return (
    <div data-e2e="faq-search-page">
      <FAQSearch searchInput={searchValue} />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ChevronLink
              rotation="left"
              appearance="t4_2"
              href={FAQ_URL_PATH}
              onClick={() => {
                resetSearchParams();
              }}
            >
              Zurück zur Übersicht
            </ChevronLink>
          </Grid.Column>
          <Spacer block b={4} />
        </Grid.Row>
        <Grid.Row ref={faqWrapperRef}>
          <Grid.Column>
            {noResult ? (
              <Spacer b={5} block>
                <Text appearance="t1_2">
                  Leider keine Treffer zu "{searchValue}".
                </Text>
              </Spacer>
            ) : (
              <>
                {faqSearchCategoryResult && (
                  <>
                    <Text appearance="t1_2">
                      Verfeinere deine Suche zu "{searchValue}":
                    </Text>
                    <div className={styles.subCategories}>
                      <Tag
                        onClick={() => handleCategorySelect()}
                        onKeyDown={(event) => handleCategoryKeyDown(event)}
                        label={`Alle (${faqSearchCategoryResult?.[0]?.count})`}
                        tabIndex={0}
                        isActive={!currentFilterValue}
                        e2e="faq-subcategory"
                      />
                      {faqSearchCategoryResult?.map((cat) => (
                        <Tag
                          onClick={() => handleCategorySelect(cat)}
                          onKeyDown={(event) =>
                            handleCategoryKeyDown(event, cat)
                          }
                          key={cat.optionValue}
                          label={`${cat.optionLabel} (${cat.optionCount})`}
                          tabIndex={0}
                          isActive={
                            `${cat.facetFilterValue}${cat.optionValue}` ===
                            currentFilterValue
                          }
                          e2e="faq-subcategory"
                        />
                      ))}
                    </div>
                  </>
                )}
                {faqSearchResult?.items && !isValidating ? (
                  <>
                    <Spacer t={4} b={2} block>
                      <FAQList
                        faqs={faqSearchResult}
                        title={searchValue ?? ''}
                        params={{
                          q: searchValue,
                        }}
                      />
                    </Spacer>
                    {noOfPages > 1 && (
                      <Spacer b={5} block>
                        <Pagination
                          start={1}
                          end={noOfPages}
                          current={currentPage.current || 1}
                          e2e="faq-list"
                          onChange={(current) => handlePagination(current)}
                          visibleSteps={10}
                        />
                      </Spacer>
                    )}
                  </>
                ) : (
                  <Spacer y={5} block>
                    <LoadingSpinner theme="blue" autoPositioning />
                  </Spacer>
                )}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
