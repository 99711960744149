import { FAQCategory, FAQList, FAQResults } from '../index';
import { useRouter } from 'next/router';
import {
  Grid,
  Spacer,
  Pagination,
  LoadingSpinner,
  ChevronLink,
} from 'dss-ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';
import useSWR from 'swr/immutable';
import { FetchFAQListParams, fetchFAQList } from '../../index';
import { FAQ_URL_PATH, NUMBER_OF_FAQS } from '../../constants';
import { useScrollTo } from '@ncs-frontend-monorepo/utils';
import { CategoryTags } from '../CategoryTags';
import { useFAQStore } from '../../store';

export interface FAQCategoryPageProps {
  mainCategoryTitle: string;
  mainCategorySlug: string;
  mainCategoryUID: number;
  activeUID: number;
  subCategories: FAQCategory[];
  defaultFaqs: FAQResults | null;
}

interface CurrentCategoryState {
  uid: number;
  faqList: FAQResults | null;
}

export const FAQCategoryPage: React.FC<FAQCategoryPageProps> = ({
  mainCategoryTitle,
  mainCategorySlug,
  mainCategoryUID,
  activeUID,
  subCategories,
  defaultFaqs,
}) => {
  const { selectedCategoryUID, setSelectedCategoryUID } = useFAQStore();
  const [currentCategory, setCurrentCategory] = useState<CurrentCategoryState>({
    faqList: defaultFaqs,
    uid: selectedCategoryUID || activeUID,
  });
  const [faqParams, setFAQParams] = useState<FetchFAQListParams>();
  const router = useRouter();
  const { data: faqListData, isValidating } = useSWR(
    () => (faqParams ? faqParams : null),
    fetchFAQList,
  );
  const faqWrapperRef = useRef<HTMLDivElement>(null);
  const scrollToTarget = useScrollTo(faqWrapperRef);

  const categoryTitle =
    subCategories.filter((cat) => cat.uid === currentCategory.uid)?.[0]
      ?.title || mainCategoryTitle;

  const handleCategorySelect = (category: FAQCategory) => {
    const isCurrentUID = category.uid === currentCategory.uid;
    const newUID = isCurrentUID ? mainCategoryUID : category.uid;
    const pathname = `${FAQ_URL_PATH}/${mainCategorySlug}/${
      isCurrentUID ? '' : `${category.slug}/`
    }`;

    setCurrentCategory({
      uid: newUID,
      faqList: null,
    });

    setSelectedCategoryUID(newUID);

    setFAQParams({
      categoryUID: newUID,
    });

    router.push(
      {
        pathname,
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const handlePagination = useCallback(
    (current: number) => {
      setFAQParams({
        categoryUID: currentCategory.uid,
        page: current,
      });
      // @TODO: Just a temporary hotfix
      // - move scrollToTarget to pagination event (needs ui-lib extension)
      if (current !== 1) {
        scrollToTarget();
      }
    },
    [currentCategory.uid],
  );

  const noOfPages = currentCategory.faqList
    ? Math.trunc(currentCategory.faqList.totalItems / NUMBER_OF_FAQS) +
      (currentCategory.faqList.totalItems % NUMBER_OF_FAQS > 0 ? 1 : 0)
    : 1;

  useEffect(() => {
    if (faqListData) {
      setCurrentCategory((current) => ({
        uid: current.uid,
        faqList: faqListData,
      }));
    }
  }, [faqListData]);

  useEffect(() => {
    const page = Number(router.query?.page) || 1;
    if (page <= noOfPages) {
      handlePagination(page);
    }
  }, [router.query?.page, noOfPages, handlePagination]);

  return (
    <div data-e2e="faq-category">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ChevronLink rotation="left" appearance="t4_2" href={FAQ_URL_PATH}>
              Zurück zur Übersicht
            </ChevronLink>
          </Grid.Column>
          <Spacer block b={4} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CategoryTags
              handleCategorySelect={handleCategorySelect}
              title={mainCategoryTitle}
              currentUID={currentCategory.uid}
              categories={subCategories}
            />
          </Grid.Column>
          <Grid.Column>
            <section ref={faqWrapperRef}>
              {currentCategory.faqList && !isValidating ? (
                <>
                  <Spacer t={5} b={2} block>
                    <FAQList
                      params={faqParams}
                      faqs={currentCategory.faqList}
                      title={categoryTitle}
                    />
                  </Spacer>
                  {noOfPages > 1 && (
                    <Pagination
                      start={1}
                      end={noOfPages}
                      current={faqParams?.page || 1}
                      e2e="faq-list"
                      onChange={(current) => handlePagination(current)}
                      visibleSteps={10}
                    />
                  )}
                </>
              ) : (
                <Spacer y={5} block>
                  <LoadingSpinner theme="blue" autoPositioning />
                </Spacer>
              )}
            </section>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
