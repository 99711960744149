import { NUMBER_OF_FAQS } from '../../constants';
import { FAQ, FAQResults } from '../../components/index';
import {
  fetcher,
  getEnv,
  replaceDynamicText,
} from '@ncs-frontend-monorepo/utils';
import { getReplaceText } from '../../helper';
import { faqRequestParams } from '.';

export type FetchFAQListParams = {
  categoryUID: number;
  page?: number;
  itemsPerPage?: number;
};
export async function fetchFAQList({
  categoryUID,
  page = 1,
  itemsPerPage = NUMBER_OF_FAQS,
}: FetchFAQListParams): Promise<FAQResults | null> {
  const params = new URLSearchParams({
    ...faqRequestParams,
    categories_uid_recursive: categoryUID.toString(),
    itemsPerPage: itemsPerPage.toString(),
    page: page.toString(),
  });

  const response = await fetcher(`${getEnv().API_V2_URL}/faqs?${params}`);

  if (!response) {
    return null;
  }

  const replaceText = getReplaceText();
  const items =
    response?.['hydra:member']?.map((faq: FAQ) => ({
      ...faq,
      question: replaceDynamicText(faq.question, replaceText),
      teaser: replaceDynamicText(faq.teaser, replaceText),
    })) || [];

  return {
    items,
    totalItems: response?.['hydra:totalItems'] || 0,
    page: response?.['hydra:view']?.['hydra:page'] || 0,
  };
}
