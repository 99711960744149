import { FAQCategory, faqRequestParams } from '../../';
import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';

type FetchFAQCategoriesParams = {
  parentCategoryUID: number;
};
export async function fetchFAQCategories({
  parentCategoryUID,
}: FetchFAQCategoriesParams): Promise<FAQCategory[]> {
  const params = new URLSearchParams({
    ...faqRequestParams,
    parentCategory_uid: parentCategoryUID.toString(),
  });

  const response = await fetcher(`${getEnv().API_V2_URL}/categories?${params}`);

  return response?.['hydra:member'] || [];
}
