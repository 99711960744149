import { getEnv } from '@ncs-frontend-monorepo/utils';

export * from './faqSearchResult';
export * from './faqCategories';
export * from './faqList';
export * from './faq';

export const faqRequestParams = {
  businessUnit: getEnv().BUSINESS_UNIT,
};
