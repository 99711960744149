import React from 'react';
import { Formik } from 'formik';
import { FAQSearchForm } from './Form';
import { useRouter } from 'next/router';
import { FAQ_SEARCH_URL_PATH } from '../../constants';

export interface SearchForm {
  searchInput?: string;
}

export const initialFAQSearchValues = {
  searchInput: '',
};

export const FAQSearch: React.FC<SearchForm> = ({ searchInput = '' }) => {
  const initialValues = { searchInput };
  const router = useRouter();

  // Handle form submission (navigates to a new search page)
  const handleSubmit = (values: { searchInput: string }) => {
    const searchQuery = new URLSearchParams({ q: values.searchInput.trim() });
    const searchURL = `${FAQ_SEARCH_URL_PATH}?${searchQuery}`;

    router.push(searchURL);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <FAQSearchForm />
    </Formik>
  );
};
