import { create } from 'zustand';
import { FAQSearchFacets } from '../';

interface FAQStore {
  selectedCategoryUID: number;
  setSelectedCategoryUID: (uid: number) => void;
  searchParams: SearchParams;
  setSearchParams: (searchParams: SearchParams) => void;
  resetSearchParams: () => void;
}

interface SearchParams {
  page?: number;
  category?: FAQSearchFacets | null;
  searchValue?: string;
}

const initialSearchParams = {
  page: 1,
  searchValue: '',
};

export const useFAQStore = create<FAQStore>((set) => ({
  selectedCategoryUID: 0,
  setSelectedCategoryUID: (uid) => set({ selectedCategoryUID: uid }),
  searchParams: initialSearchParams,
  setSearchParams: (searchParams) =>
    set((state) => ({
      ...state,
      searchParams: { ...state.searchParams, ...searchParams },
    })),
  resetSearchParams: () => set({ searchParams: initialSearchParams }),
}));
