import { NUMBER_OF_FAQS } from '../../constants';
import { FAQ, FAQSearchFacets, FAQSearchResult } from '../../components';
import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';
import { faqRequestParams } from '.';

export type FetchFAQSearchResultParams = {
  searchTerm: string;
  itemsPerPage?: number;
  page?: number;
  categoryFilter?: string | null;
};
export async function fetchFAQSearchResult({
  itemsPerPage = NUMBER_OF_FAQS,
  page = 1,
  searchTerm,
  categoryFilter,
}: FetchFAQSearchResultParams): Promise<FAQSearchResult | null> {
  const params = new URLSearchParams({
    ...faqRequestParams,
    itemsPerPage: itemsPerPage.toString(),
    page: page.toString(),
    q: searchTerm,
    ...(categoryFilter ? { 'filter[]': categoryFilter } : {}),
  });

  try {
    const response = await fetcher(
      `${
        getEnv().API_V2_URL
      }/solr/search_results?filter[]=type:tx_ncfaq_domain_model_faq&${params}`,
    );

    if (!response) {
      return null;
    }

    const items =
      response?.['hydra:member']?.map((faq: FAQ) => ({
        ...faq,
        uid: faq.dataObject?.uid,
        question: faq.dataObject?.question || '',
        slugBreadcrumb: faq.dataObject?.slugBreadcrumb || '',
        teaser: faq.dataObject?.teaser || '',
      })) || [];

    return {
      items,
      page: response?.['hydra:view']?.['hydra:page'] || 0,
      question: searchTerm,
      totalItems: response?.['hydra:totalItems'] || 0,
    };
  } catch (error) {
    console.error('Failed to fetch FAQ search results:', error);
    return null;
  }
}

export const fetchFaqSearchCategories = async ({
  searchTerm,
}: {
  searchTerm: string;
}): Promise<FAQSearchFacets[] | null> => {
  const params = new URLSearchParams({
    q: searchTerm,
  });
  try {
    const responseCategory = await fetcher(
      `${
        getEnv().API_V2_URL
      }/solr/search_facets?filter[]=type:tx_ncfaq_domain_model_faq&name=category&${params}`,
    );

    return responseCategory?.['hydra:member'] || null;
  } catch (error) {
    console.error('Failed to fetch FAQ search results:', error);
    return null;
  }
};
