import { fetchFAQ, fetchFAQList } from '../';
import { getFAQBreadcrumb } from './getFAQBreadcrumb';
import { ContentType } from '@ncs-frontend-monorepo/content-library';

export const getFAQPage = async ({
  path,
  categoryUID,
}: {
  path: string;
  categoryUID: number;
}) => {
  const faqSlug = path.split('/').at(-2); // last item is an empty string => trailingSlash

  // fetch faqs for specific category
  const faqList = await fetchFAQList({ categoryUID, itemsPerPage: 9999 });

  if (faqList) {
    const faqItem = faqList.items.find((item) => item.slug === faqSlug);

    if (faqItem) {
      const faq = await fetchFAQ({ faqUID: faqItem.uid });

      if (faq) {
        const breadcrumbs = getFAQBreadcrumb(faq);
        return {
          pageId: 54321,
          content: [
            {
              id: 11111110,
              component: ContentType.FAQ_PAGE,
              props: {
                id: 45678777,
                faq,
              },
            },
          ],
          meta: {
            seoTitle: faq?.seoTitle || faq?.question || '',
            title: faq?.question || '',
            description: faq?.seoDescription || '',
          },
          breadcrumbs,
        };
      }
    }
  }

  return null;
};
