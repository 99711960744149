import { Tag, Text } from 'dss-ui-library';
import styles from './CategoryTags.module.scss';
import { FAQCategory } from '../index';
import { useCallback } from 'react';

interface CategoryTagsProps {
  title: string;
  categories: FAQCategory[];
  handleCategorySelect(category: FAQCategory): void;
  currentUID: number | string;
}

export const CategoryTags: React.FC<CategoryTagsProps> = ({
  title,
  categories,
  handleCategorySelect,
  currentUID,
}) => {
  const handleCategoryKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>, category: FAQCategory) => {
      if (event.key === 'Enter') {
        handleCategorySelect(category);
      }
    },
    [handleCategorySelect],
  );

  return (
    <>
      <Text appearance="t1_2">Verfeinere deine Suche zu "{title}":</Text>
      <div className={styles.subCategories}>
        {categories.map((cat) => (
          <Tag
            onClick={() => handleCategorySelect(cat)}
            onKeyDown={(event) => handleCategoryKeyDown(event, cat)}
            key={cat.uid}
            label={cat.title}
            tabIndex={0}
            isActive={cat.uid === currentUID}
            e2e="faq-subcategory"
          />
        ))}
      </div>
    </>
  );
};
