import Link from 'next/link';
import { IconChevron, Spacer, Text, ListIcon } from 'dss-ui-library';
import styles from './FAQCategoryLink.module.scss';
import { FAQ_URL_PATH } from '../../constants';
interface FAQCategoryLinkProps {
  title: string;
  slug: string;
  icon: number;
}

export function FAQCategoryLink({
  title,
  icon,
  slug,
}: FAQCategoryLinkProps): React.ReactNode {
  return (
    <Link href={FAQ_URL_PATH + '/' + slug}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <div className={styles.icon}>
            <ListIcon iconType={icon} size={24} iconColor="blue" />
          </div>
          <Spacer l={1} />
          <Text appearance="t4_2">{title}</Text>
        </div>
        <IconChevron
          className={styles.chevron}
          rotation="right"
          color="blue"
          small
          scale="small"
        />
      </div>
    </Link>
  );
}
